import {Button, Form, Input, Modal} from "@arco-design/web-react";
import {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import React from 'react'
import {useQueryClient} from "@tanstack/react-query";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

function AddToDo(){
    const [queryClient] = React.useState(useQueryClient());

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    function onOk() {
        form.validate().then(() => {
            setConfirmLoading(true);
            const toast_id = toast.loading("Einen Moment bitte...");

            axios.post(
                "https://api.lpa-ug.de/todo/task/",
                {title: form.getFieldValue("title"), description: form.getFieldValue("description")}
            )
                .then(() => {
                    toast.update(toast_id, {
                        render: '🦄 Task erstellt!',
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        isLoading: false,
                        type: "success"
                    });

                    queryClient.invalidateQueries(['tasks']).then(() => void(0))
                })
                .catch(error => {
                    toast.update(toast_id, {
                        render: '🦄 Task konnte nicht erledigt werden!',
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        isLoading: false,
                        type: "error"
                    });
                    console.log(error);
                }).finally(() => {setConfirmLoading(false); setVisible(false); form.clearFields();})
        });
    }


    return(
        <div>
            <Button onClick={() => setVisible(true)} type='outline'>
                Neues ToDo
            </Button>
            <Modal
                title='ToDo hinzufügen'
                visible={visible}
                onOk={onOk}
                confirmLoading={confirmLoading}
                onCancel={() => setVisible(false)}
                okText={"Hinzufügen"}
                cancelText={"Abbrechen"}
            >
                <Form form={form} validateMessages={{
                    required: (_, { label }) => `Das Feld ${label} wird benötigt.`,
                }}>
                    <FormItem label='Titel' field='title' rules={[{ required: true }]}>
                        <Input placeholder='Titel...' />
                    </FormItem>
                    <FormItem label='Text' required field='description' rules={[{ required: true }]}>
                        <TextArea placeholder='Beschreibung...' style={{ minHeight: 64 }} />
                    </FormItem>
                </Form>
            </Modal>
        </div>
    )
}

export default AddToDo;