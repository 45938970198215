import {Button, Checkbox, Form, Input, Typography} from "@arco-design/web-react";

function Login(){
    const FormItem = Form.Item;

    const handleSubmission = e => {

        localStorage.setItem("username", e.email);
        localStorage.setItem("password", e.password);

        window.location.reload();
    }

    return(
        <div className={"p-8 2xl:px-96"}>
            <Typography>
                <Typography.Title>Beste ToDo App</Typography.Title>
            </Typography>

            <Form onSubmit={(v) => {
                handleSubmission(v);
            }}>
                <FormItem field='email' label='E-Mail'>
                    <Input placeholder='vorname@mail.de' />
                </FormItem>
                <FormItem field='password' label='Passwort' >
                    <Input.Password defaultValue=''/>
                </FormItem>
                <FormItem wrapperCol={{ offset: 5 }}>
                    <Checkbox>Ich akzeptiere alles.</Checkbox>
                </FormItem>
                <FormItem wrapperCol={{ offset: 5 }}>
                    <Button htmlType={"submit"} type='primary'>Anmelden</Button>
                </FormItem>
            </Form>
        </div>
    )
}

export default Login;