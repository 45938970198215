import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './App.css';
import "@arco-design/web-react/dist/css/arco.css";
import 'react-toastify/dist/ReactToastify.css';

import Main from "./Main";
import {ToastContainer} from "react-toastify";

function App() {
    if(localStorage.getItem("darkmode") === "yes")
        document.body.setAttribute('arco-theme', 'dark')
    else
        document.body.removeAttribute('arco-theme')

    const queryClient = new QueryClient();

  return (
      <QueryClientProvider client={queryClient}>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
          />
          <Main/>
      </QueryClientProvider>
  );
}

export default App;
