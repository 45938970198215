import React from 'react'
import {Card} from '@arco-design/web-react';
import TaskControl from "./TaskControl";
import Linkify from 'react-linkify';

class Task extends React.Component {

    render() {

        let textFormatting = "new-line";
        if(this.props.obj.systemstatus === 1) textFormatting = "line-through new-line";


        return (
            <div>
                    <Card
                        style={{ marginBottom: 20 }}
                        title={<div className={textFormatting}>{this.props.obj.title}</div>}
                        extra={<TaskControl task={this.props.obj} />}
                    >
                        <div className={textFormatting}><Linkify>{this.props.obj.description}</Linkify></div>
                    </Card>

            </div>
        )
    }
}

export default Task;