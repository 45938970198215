import {Comment, Message, Popconfirm} from "@arco-design/web-react";
import {IconDelete} from "@arco-design/web-react/icon";
import React from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {useQueryClient} from "@tanstack/react-query";

function TaskComment(props) {
    const [queryClient] = React.useState(useQueryClient());

    return (
        <Comment
            align='right'
            actions={
                <Popconfirm
                    title='Soll dieses Kommentar gelöscht werden?'
                    okText={"Ja"}
                    cancelText={"Nein"}
                    onOk={() => {
                        const comment_id = props.obj.id;

                        const toast_id = toast.loading("Einen Moment bitte...");

                        axios.patch(
                            "https://api.lpa-ug.de/todo/comment/" + comment_id + "/",
                            {systemstatus: 11}
                        )
                            .then(data => {
                                toast.update(toast_id, {
                                    render: "Kommentar erfolgreich gelöscht!",
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    isLoading: false,
                                    type: "success"
                                });

                                queryClient.invalidateQueries(['taskComments' + props.obj.task]).then(() => void(0))
                            })
                            .catch(error => {
                                toast.update(toast_id, {
                                    render: '🦄 Kommentar konnte nicht gelöscht werden!',
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    isLoading: false,
                                    type: "error"
                                });
                                console.log(error);
                            })
                    }}
                    onCancel={() => {
                        Message.error({
                            content: 'Abgebrochen',
                        });
                    }}
                >
                    <span className='custom-comment-action'>          <IconDelete/> Löschen        </span>
                </Popconfirm>
            }
            author={props.obj.user.username}
            //avatar='//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/9eeb1800d9b78349b24682c3518ac4a3.png~tplv-uwbnlip3yd-webp.webp'
            content={
                <div>
                    {props.obj.description}
                </div>
            }
            datetime='1 hour'
        />
    )
}

export default TaskComment;