import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import Task from "./components/Task";
import {
    Badge,
    Card
} from "@arco-design/web-react";
import Header from "./components/Header";
import Login from "./components/Login";


function Main(){
    const usernameSet = localStorage.getItem("username") !== undefined && localStorage.getItem("username") !== null && localStorage.getItem("username") !== "";

    axios.defaults.headers.common['x_mail'] = localStorage.getItem("username");
    axios.defaults.headers.common['x_code'] = localStorage.getItem("password");


    const {isLoading: taskLoading, error: taskError, data: taskData} = useQuery(['tasks'], () =>
            axios.get(
                "https://api.lpa-ug.de/todo/task/",
            ).then((res) => res.data)
        ,
        {
            // dont Refetch the data every second
            // refetchInterval: 1000,
        }
    );

    if (!usernameSet) {
        return (
            <Login />
        )
    }

    let showThisStatus = localStorage.getItem("showStatus")
    if (showThisStatus === null) showThisStatus = 0
    else showThisStatus = parseInt(showThisStatus)

    const filteredTasks = taskData?.filter(f => f.systemstatus === showThisStatus)

    return (
        <div>
            <Header />

            <div className={"p-8 2xl:px-96"}>

                <Card title='Hier sind deine ToDos' extra={<Badge count={filteredTasks?.length??0} />}>
                    {taskLoading
                        ? ('Loading...')
                        : taskError
                            ? 'Error!'
                            : taskData
                                ? filteredTasks.map((todo) => <Task key={todo.id} obj={todo}/>)
                                : null}
                </Card>
            </div>
        </div>
    )
}



export default Main;