import {Avatar, Dropdown, Menu, PageHeader} from "@arco-design/web-react";
import {IconCheck, IconLock, IconMoon, IconPen, IconSun} from "@arco-design/web-react/icon";
import AddToDo from "./AddToDo";

function Header(){

    const iconStyle = {
        marginRight: 8,
        fontSize: 16,
        transform: 'translateY(1px)',
    };

    function logout() {
        localStorage.removeItem("username")
        localStorage.removeItem("password")
        window.location.reload();
    }

    function darkmode() {
        if(localStorage.getItem("darkmode") === "yes"){
            localStorage.removeItem("darkmode");
        }
        else{
            localStorage.setItem("darkmode", "yes");
        }

        window.location.reload();
    }

    let darkmodeItem = <Menu.Item key='1' onClick={darkmode}>
        <IconMoon style={iconStyle} />
        Darkmode
    </Menu.Item>

    if(localStorage.getItem("darkmode") === "yes"){
        darkmodeItem = <Menu.Item key='1' onClick={darkmode}>
            <IconSun style={iconStyle} />
            Lightmode
        </Menu.Item>
    }

    function toggleHistory() {
        if(localStorage.getItem("showStatus") === "1"){
            localStorage.setItem("showStatus", "0");
        }
        else{
            localStorage.setItem("showStatus", "1");
        }

        window.location.reload();
    }
    let historyItem = <Menu.Item key='2' onClick={toggleHistory}>
        <IconCheck style={iconStyle} />
        Erledigte anzeigen
    </Menu.Item>

    if(localStorage.getItem("showStatus") === "1"){
        historyItem = <Menu.Item key='2' onClick={toggleHistory}>
            <IconPen style={iconStyle} />
            ToDos anzeigen
        </Menu.Item>
    }



    const dropList = (
        <Menu>
            {darkmodeItem}
            {historyItem}
            <Menu.Item key='2' onClick={logout}>
                <IconLock style={iconStyle} />
                Logout
            </Menu.Item>
        </Menu>
    );

    return(
        <PageHeader
            style={{ background: 'var(--color-bg-2)' }}
            title='ToDo'
            subTitle='Die beste ToDo App der Welt'
            extra={
                <div>
                    <AddToDo />
                    <Dropdown droplist={dropList} position='bl'>
                        <Avatar>{localStorage.getItem("username")[0]}</Avatar>
                    </Dropdown>
                </div>
            }
        />
        )

}

export default Header;