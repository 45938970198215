import {Button, Comment, Descriptions, Input, Modal} from "@arco-design/web-react";
import {useState} from "react";
import axios from "axios";
import React from 'react'
import {useQuery, useQueryClient} from "@tanstack/react-query";
import TaskComment from "./TaskComment";
import {toast} from "react-toastify";
import Linkify from 'react-linkify';
function timeDifference(text, current, previous) {

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return text + "vor " + Math.round(elapsed/1000) + ' Sekunden';
    }

    else if (elapsed < msPerHour) {
        return text + "vor " + Math.round(elapsed/msPerMinute) + ' Minuten';
    }

    else if (elapsed < msPerDay ) {
        return text + "vor " + Math.round(elapsed/msPerHour ) + ' Stunden';
    }

    else if (elapsed < msPerMonth) {
        return text + "vor ~" + Math.round(elapsed/msPerDay) + ' Tagen';
    }

    else if (elapsed < msPerYear) {
        return text + "vor ~" + Math.round(elapsed/msPerMonth) + ' Monaten';
    }

    else {
        return text + '~ ' + Math.round(elapsed/msPerYear ) + ' years ago';
    }
}



function ShowDetails({visible, setVisible, task}){
    const [confirmLoading] = useState(false);
    const [queryClient] = React.useState(useQueryClient());

    const taskCreationDate = new Date(task.createdAt);
    const taskCreationDateText = taskCreationDate.toLocaleDateString("de-DE", { // you can use undefined as first argument
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    })

    let data = [
        {
            label: 'Erstellt am',
            value: taskCreationDateText + " Uhr \n(" + timeDifference("", Date.now(), taskCreationDate) + ")",
            span: 4,
        }
    ];

    const {isLoading: commentsLoading, error: commentsError, data: commentsData} = useQuery(['taskComments' + task.id], () =>
            axios.get(
                "https://api.lpa-ug.de/todo/task/" + task.id + "/get_comments/",
            ).then((res) => res.data)
        ,
        {
            // dont Refetch the data every second
            // refetchInterval: 1000,
        }
    );

    console.log("https://api.lpa-ug.de/todo/task/" + task.id + "/get_comments/")

    function PostComment(text, task_id){

        const toast_id = toast.loading("Einen Moment bitte...");

        axios.post(
            "https://api.lpa-ug.de/todo/comment/",
            {description: text, task: task_id}
        )
            .then(() => {
                toast.update(toast_id, {
                    render: '🦄 Kommentar erstellt!',
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "success"
                });

                queryClient.invalidateQueries(['taskComments' + task.id]).then(() => void(0))
            })
            .catch(error => {
                toast.update(toast_id, {
                    render: '🦄 Kommentar konnte nicht erstellt werden!',
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "error"
                });
                console.log(error);
            }).finally(() => {})
    }

    return(
        <div>
            <Modal
                title={task.title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <div className={"new-line"}><Linkify>{task.description}</Linkify></div> <br/> <br/>
                <Descriptions
                    data={data}
                    border
                    column={{
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2,
                        xxl: 2,
                    }}
                />
                <br/>
                Kommentare:

                {commentsLoading
                    ? ('Loading...')
                    : commentsError
                        ? 'Error!'
                        : commentsData
                            ? commentsData.map((comment) => <TaskComment key={comment.id} obj={comment}/>)
                            : null}

                <Comment
                    align='right'
                    actions={[
                        <Button key='0' type='primary' onClick={(e) => {
                            const input_field = e.target.parentElement.parentElement.parentElement.children[0]
                            const comment_text = input_field.textContent
                            PostComment(comment_text, task.id)
                        }}>
                            Absenden
                        </Button>,
                    ]}
                    //avatar='//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/e278888093bef8910e829486fb45dd69.png~tplv-uwbnlip3yd-webp.webp'
                    content={
                        <div>
                            <Input.TextArea placeholder='Hier kannst du einen Kommentar hinzufügen.' />
                        </div>
                    }
                />
            </Modal>
        </div>
    )
}

export default ShowDetails;