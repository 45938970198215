import {IconCheck, IconDelete, IconMore, IconRedo, IconUp} from "@arco-design/web-react/icon";
import {Button, Dropdown, Menu} from "@arco-design/web-react";
import React from 'react'
import {toast} from "react-toastify";
import axios from "axios";
import {useQueryClient} from "@tanstack/react-query";
import ShowDetails from "./ShowDetails";
import {useState} from "react";

const ButtonGroup = Button.Group;



function TaskControl ({task}) {

    const [todoDetailsVisible, setToDoDetailsTodoDetailsVisible] = useState(false);

    function increasePrio() {
        const toast_id = toast.loading("Einen Moment bitte...");

        axios.post(
            "https://api.lpa-ug.de/todo/task/" + task.id + "/prioritise/"
        )
            .then(() => {
                toast.update(toast_id, {
                    render: "🚀 ToDo wurde priorisiert",
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "success"
                });

                queryClient.invalidateQueries(['tasks']).then(() => void(0))
            })
            .catch(error => {
                toast.update(toast_id, {
                    render: '🦄 Task konnte nicht verändert werden!',
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "error"
                });
                console.log(error);
            })
    }

    function openDetails() {
        setToDoDetailsTodoDetailsVisible(true)
    }

    const dropList = (
        <Menu onClickMenuItem={(key) => {
            if(key === '0')
                openDetails();
            if (key === '1')
                increasePrio();
            if (key === '2')
                changeSystemstatus(11, '🚮 Task gelöscht!');
        }
        }>
            <Menu.Item key='0'><IconUp /> Details anzeigen</Menu.Item>
            <Menu.Item key='1'><IconUp /> Priorisieren</Menu.Item>
            <Menu.Item key='2'><IconDelete /> Löschen</Menu.Item>
        </Menu>
    );

    const [queryClient] = React.useState(useQueryClient());

    function changeSystemstatus(targetSystemstatus, successText) {
        const toast_id = toast.loading("Einen Moment bitte...");

        axios.patch(
            "https://api.lpa-ug.de/todo/task/" + task.id + "/",
            {systemstatus: targetSystemstatus}
        )
            .then(() => {
                toast.update(toast_id, {
                    render: successText,
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "success"
                });

                queryClient.invalidateQueries(['tasks']).then(() => void(0))
            })
            .catch(error => {
                toast.update(toast_id, {
                    render: '🦄 Task konnte nicht verändert werden!',
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "error"
                });
                console.log(error);
            })
    }

    function revert() {
        const toast_id = toast.loading("Einen Moment bitte...");

        axios.patch(
            "https://api.lpa-ug.de/todo/task/" + task.id + "/",
            {systemstatus: 0}
        )
            .then(() => {
                toast.update(toast_id, {
                    render: '🦄 Erledigen rückgängig gemacht!',
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "info"
                });

                queryClient.invalidateQueries(['tasks']).then(() => void(0))
            })
            .catch(error => {
                toast.update(toast_id, {
                    render: '🦄 Task konnte nicht reaktiviert werden!',
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    isLoading: false,
                    type: "error"
                });
                console.log(error);
            })
    }


    let actionButton = <Button type='outline' icon={<IconCheck />} onClick={()=>changeSystemstatus(1, '🦄 Task erledigt!')}>Erledigt</Button>

    if(task.systemstatus === 1)
        actionButton = <Button type='outline' icon={<IconRedo />} onClick={revert}>ToDo.. :(</Button>

    return(
        <div>
            <ShowDetails setVisible={setToDoDetailsTodoDetailsVisible} visible={todoDetailsVisible} task={task}/>
            <ButtonGroup>
                {actionButton}

                <Dropdown droplist={dropList} trigger='click' position='br'>
                    <Button type='outline' icon={<IconMore />} />
                </Dropdown>

            </ButtonGroup>
        </div>

    )
}

export default TaskControl;